/* Old Version */
.App {
  width: 100vw;
  overflow-x: hidden;
  background: var(--wl-background);
  font-family: "Kiwi Maru", serif !important;
  font-style: normal !important;
}

section {
  scroll-margin-top: 100px;
}

:root {
  --headshotSize: 300px;
}

.app-content {
  width: 100vw;
}

.red-glow-light {
  filter: drop-shadow(0px 0px 5px #EF242766);
}

.home-bio-container {
  min-height: 800px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  min-height: 400px;
}

.home-top-left-col {
  background-color: transparent;
  justify-content: flex-end;
  z-index: 2;
}

.dreams-img-large {
  border: .5rem solid #1a1a1e;
  max-height: 500px;
  width: auto;
}

.home-top-headshot {
  border: .5rem solid #1a1a1e;
  width: 100%;
  height: auto;
  max-width: var(--headshotSize);
  max-height: var(--headshotSize);
  border-radius: 50%;
}

.header-top-text {
  justify-content: start;
  width: 100%;
}

@media (max-width: 990px) {
  .home-top-left-col {
    justify-content: center;
  }

  .header-top-text {
    justify-content: center;
    width: 100vw;
    text-align: center;
  }
  
  .home-top-headshot {
    border: 0.5rem solid #070709;
    align-self: center;
    left: 50%
  }
  
  .headshot-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.bg-transparent-red {
  background-color: #EF242744;
}

.sticky-walls {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.water {
  background: linear-gradient(to bottom, #24efec22, transparent)
}