.wl-rounded-button {
  background: var(--wl-button-color) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3rem;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 600;
}

.wl-rounded-button:hover {
  background: var(--wl-button-hover-color) !important;
}
